export const query = `query getData($externalId: String!, $compid: String!, $withSubscriptionPlans: Boolean = false) {
  appSettings(externalId: $externalId) {
    widgetSettings
  }
  catalog {
    product(compId: $compid, onlyVisible: true) {
      id
      name
      urlPart
      price
      comparePrice
      formattedPrice
      formattedComparePrice
      hasOptions
      media {
        id
        url
        mediaType
        width
        height
        index
      }
      ribbon
      isInStock
      productType
      digitalProductFileItems {
        fileType
      }
      productType
      subscriptionPlans @include(if: $withSubscriptionPlans) {
        list {
           id
           visible
        }
      }
    }
  }
}`;
